import { NgModule } from '@angular/core';
import { SignaturePadComponent } from './angular-signature-pad.component';

@NgModule({
  declarations: [SignaturePadComponent],
  imports: [],
  exports: [SignaturePadComponent],
})
export class AngularSignaturePadModule {
}
